import { useContext } from "react";
import { Context } from ".";
import context from "./context"; // the additional aframe scene context

const useScenes = () => {
	const scenes = useContext(Context);
	return scenes;
};

export const useScene3D = () => {
	const scene = useContext(context)
	return scene;
}

export default useScenes;
