const isObject = (item: any): boolean => {
	return item !== null && typeof item === "object" && !Array.isArray(item);
};

const deepMerge = (
	target: Record<string, unknown>,
	source: Record<string, unknown>,
) => {
	const output = { ...target };

	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach((key) => {
			if (isObject(source[key])) {
				if (!(key in target)) {
					Object.assign(output, { [key]: source[key] });
				} else {
					output[key] = deepMerge(
						target[key] as Record<string, unknown>,
						source[key] as Record<string, unknown>,
					);
				}
			} else {
				Object.assign(output, { [key]: source[key] });
			}
		});
	}

	return output;
};

export { deepMerge };
