import { createContext } from "react";

import PageQuery from "common/types/PageQuery";
import { SceneContextModel } from "./types";

const initialQuery: PageQuery = {
	filters: {},
	limit: 10,
	page: 0,
	search: {
		text: "",
		fields: [],
	},
	sort: "meta.name",
};

const context = createContext<SceneContextModel>({
	selectors: {
        getVisible: () => {
            throw new Error("toolbarDispatch - getVisible not implemented");
        },
        getDisabled: () => {
            throw new Error("toolbarDispatch - getDisabled not implemented");
        },
        getSelected: () => {
            throw new Error("toolbarDispatch - getSelected not implemented");
        },
        getLoading: () => {
            throw new Error("toolbarDispatch - getLoading not implemented");
        },
        getLabel: () => {
            throw new Error("toolbarDispatch - getLabel not implemented");
        },
    },
    toolbarDispatch: () => {
        throw new Error("toolbarDispatch not implemented");
    },
    toolbarState: {},
    toolbarHandlers: {},
    getHandler: () => {
        throw new Error("tgetHandler not implemented");
    },
    updateHandler: () => {
        throw new Error("updateHandler not implemented");
    },
    updateAllHandlers: () => {
        throw new Error("updateAllHandlers not implemented");
    },
    translate: () => {
        throw new Error("translate function not implemented");
    },
});

export default context;
