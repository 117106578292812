const HIGHLIGHT_WIDTH = 2;
const HIGHLIGHT_SELECTOR = '[data-component]';
const HIGHLIGHTER_CLASS = "component-highlighter"

function createHighlighterForElement(component: HTMLElement) {
	const highlighter = document.createElement('div');
	highlighter.id = component.getAttribute("data-component") || "";
	highlighter.classList.add(HIGHLIGHTER_CLASS);

	const label = document.createElement('span');
	label.style.cssText = `
	background-color: #F7817F;
	padding: 5px 10px;
	color: white;
	font-size: 11px;
	pointer-events: all;
	cursor: pointer;
	height: 20px;
    border-radius: 10px;
    display: block;
    width: fit-content;
    margin-top: 2px;
    margin-left: 2px;
    line-height: 10px;
	`
	label.innerText = highlighter.id;
	highlighter.appendChild(label);

	const { x, y, width, height } = component.getBoundingClientRect();
	highlighter.style.cssText = `
    top: ${y + window.scrollY}px;
    left: ${x}px;
    height: ${height}px;
    width: ${width}px;
    border: ${HIGHLIGHT_WIDTH}px solid #F7817F;
	position: absolute;
	pointer-events: none;
  `;

	label.addEventListener("click", () => {
		const componentPath = component.getAttribute("data-path");
		if (componentPath) {
			openInNewTab(`https://gitlab.com/hegias/hegias-cms/-/tree/master/${componentPath}`);
		}
	});

	return highlighter;
}

export function highlightComponents() {
	const components = document.querySelectorAll<HTMLElement>(HIGHLIGHT_SELECTOR);

	components.forEach((component) => {
		const id = component.getAttribute("data-component")
		const exist = id && document.getElementById(id);
		if (exist) {
			return;
		}

		const highlighter = createHighlighterForElement(component);
		document.body.appendChild(highlighter);
	});
}

export function clearHighlights() {
	document.querySelectorAll<HTMLElement>(`.${HIGHLIGHTER_CLASS}`)
		.forEach(e => e?.parentNode?.removeChild?.(e));

}

function openInNewTab(url: string) {
	const win = window.open(url, '_blank');
	win?.focus?.();
}
