import React, { ComponentProps } from "react";
import { Select, Tag } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Icon } from "@hegias/ui-components";

export declare type CustomTagProps = {
	label: any;
	value: any;
	disabled: boolean;
	onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	closable: boolean;
};

export interface InputTagProps extends ComponentProps<typeof Select> {
	tags: { value: string; label: string }[];
	handleOnClose?: () => void;
}

const InputTag = ({ tags, handleOnClose, ...rest }: InputTagProps) => {
	const { t } = useTranslation();

	const tagRender = (props: CustomTagProps) => {
		const { label, closable, onClose } = props;

		return (
			<StyledTag
				closable={closable}
				onClose={() => {
					onClose();
					if (handleOnClose) {
						handleOnClose();
					}
				}}
				style={{ marginRight: 3 }}
				closeIcon={
					<Icon
						name="CloseIcon"
						color="white"
						size="10px"
						hoverColor="grey30"
					/>
				}
			>
				{label}
			</StyledTag>
		);
	};

	return (
		<StyledSelect
			{...rest}
			tagRender={tagRender}
			placeholder={t("app.global.placeholder.tags")}
			filterOption={(inputValue, option) => {
				return option?.value
					.toLowerCase()
					.includes(inputValue.toLowerCase());
			}}
		>
			{tags.map(({ value, label }: { value: string; label: string }) => (
				<Select.Option value={value} key={value}>
					{label}
				</Select.Option>
			))}
		</StyledSelect>
	);
};

const StyledSelect = styled(Select)`
	&& {
		min-height: ${({ theme }) => theme.size.form.input}px;
		height: auto;
		padding: 2px 0;
		background-color: ${({ theme }) => theme.colors.grey400};
		display: flex;
		align-items: center;
		width: 100%;
		> div {
			background-color: ${({ theme }) => theme.colors.grey400};
			border: ${({ theme }) => theme.colors.grey300};
			height: 100%;
			width: 100%;
			min-height: 40px;
		}
		&.ant-select-focused:not(.ant-select-disabled) {
			.ant-select-selector {
				border-color: ${({ theme }) => theme.colors.grey200};
				box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.grey100};
			}
		}
		&:not(.ant-select-disabled):hover {
			.ant-select-selector {
				border-color: ${({ theme }) => theme.colors.grey200};
				box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.grey100};
			}
		}
	}
`;

const StyledTag = styled(Tag)`
	&& {
		height: ${({ theme }) => theme.size.form.input - 10}px;
		background-color: ${({ theme }) => theme.colors.primary};
		color: ${({ theme }) => theme.colors.white};
		display: flex;
		align-items: center;
		border-radius: 5px;
		div.ant-tag-close-icon {
			display: flex;
			justify-content: flex-end;
			margin-left: 5px;
		}
	}
`;

export default InputTag;
